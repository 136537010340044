* {
  color: #303030;
}

body {
  background-color: #f0f0f0;
  background-image: url("/images/skyline.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.backdrop {
  background-color: rgba(255, 255, 255, 0.6);
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  z-index: -1;
}

.header {
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
}

.page {
  margin-top: 56px;
  max-width: 1100px;
  min-height: calc(100vh - 56px);
  background-color: white;
}

.footer {
  height: 50px;
  position: relative;
  background-color: white;
  max-width: 1100px;
  margin: 0 auto;
}

.footer-content {
  position: absolute;
  font-size: small;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
}

.logo {
  position: fixed;
  top: 0px;
  left: 10px;
  height: 56px;
}

.logo > img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  height: 35px;
}

.slide {
  background-color: transparent !important;
}

.slider-wrapper {
  height: 425px !important;
}

.slider-wrapper > ul {
  height: 100% !important;
}

.slider-wrapper > ul > li {
  position: relative;
  height: 100% !important;
}

.slider-wrapper > ul > li > img {
  height: auto !important;
  width: auto !important;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-link {
  color: inherit !important;
}

.home-link-block {
  color: inherit !important;
  display: block;
}
